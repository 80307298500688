
import { defineComponent, ref } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import AssociationContractModal from '@/layout/header/partials/association/AssociationContractDrawer.vue';
import AssociationContractModalView from '@/layout/header/partials/association/AssociationContractViewDrawer.vue';
import { VueCookieNext } from 'vue-cookie-next';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import moment from 'moment';
import Multiselect from '@vueform/multiselect';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Training Partner Coqntracts',
  components: {
    Datatable,
    AssociationContractModal,
    Form,
    Field,
    ErrorMessage,
    AssociationContractModalView,
    Multiselect,
  },
  setup() {
    const entityInfoID = 5;
    return {
      entityInfoID,
    };
  },

  data() {
    return {
      dli_id: '',
      dlis: '' as any,
      trancheInfo: '' as any,
      batch: {
        association_info_id: [] as any,
        tranche_id: [] as any,
      },
      association: [] as any,
      contract: {
        dli_id: '',
        id: '',
        entity_info_id: '',
        tranche_id: '',
        contract_number: '',
        contract_name: '',
        sign_date: '',
        start: '',
        end: '',
        target: '',
        amount: '',
        contribution_percentage: '',
        adjustable_advance_amount: '',
        min_age: '',
        max_age: '',
        age_grace_in_month: '',
        active_status: '',
        status_remarks: '',
        user_id: '',
      },
      tableHeader: [
        {
          name: 'Action',
          key: 'actions',
          sortable: true,
          width: '150px',
        },
        {
          name: 'SL',
          key: 'sl',
          sortable: true,
        },
        {
          name: 'Training Partner',
          key: 'training_partner',
          sortable: true,
        },
        {
          name: 'Phase',
          key: 'tranche',
          sortable: true,
        },
        {
          name: 'Contract Number',
          key: 'contract_number',
          sortable: true,
        },
        {
          name: 'Contract Name',
          key: 'contract_name',
          sortable: false,
        },
        {
          name: 'Sign Date',
          key: 'sign_date',
          sortable: false,
          width: '100px',
        },
        {
          name: 'Start',
          key: 'start',
          width: '120px',
          sortable: false,
        },
        {
          name: 'End',
          key: 'end',
          width: '120px',
          sortable: false,
        },
        {
          name: 'Training Target',
          key: 'training_target',
          sortable: false,
        },
        {
          name: 'Contract Amount',
          key: 'contract_amount',
          sortable: false,
        },
        {
          name: 'Attachment',
          key: 'attachment',
          sortable: false,
        },
      ],
      tableData: [],
      tranche: [] as any,
      moment: '' as any,
      loading: false,
      lists: [],
      search: '',
      entity_type: '',
      data: {},
      tabIndex: ref(0),
      entityInfos: [],
      entityTypes: [],
      componentKey: 0,
      index: 0,
      trancheData: [] as any,
      load: false,
      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
      optionsTranche: [] as any,
    };
  },
  async created() {
    this.load = true;
    this.moment = moment;
    // await this.getContactList();
    this.entity_type = VueCookieNext.getCookie('_seip_entity_type');

    await this.associationList();
    await this.getTrancheInfo();
    await this.getDliInfo();
    Object.assign(this.tableData, this.lists);
    this.emitter.on('association-updated', async () => {
      this.getContactList();
      this.tableData = this.lists;
    });
  },
  methods: {
    filterEntity(entity) {
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.associationData.push(entity);
      if (entity.includes('select')) {
        this.associationData = [] as any;
        this.associationData.push(this.tempId);
        this.association.shift();

        this.association.forEach((val, index) => {
          if (val.id != 'select') {
            this.batch.association_info_id.push(val.id);
          }
        });

        // association khali kore push korabo unselect status
        this.association = [];
        this.association.push(this.UnselectObj);
      }
      if (entity.includes('unselect')) {
        this.associationData = [];
        //unshift koro select obj
        this.association = [];

        this.association = this.tempAssociation;
        this.association.unshift(this.selectObj);
        this.batch.association_info_id = '';
        // tempassociation theke association vorbo
        //batch.association id empty
      }
    },
    async associationList() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
          this.tempAssociation = this.association;

          this.tempAssociation.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.tempId.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTrancheInfo() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
          this.tranche.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
     async getDliInfo() {
      this.load = true;
      await ApiService.get('dli/list')
        .then((response) => {
          this.dlis = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    setActiveTab(event) {
      // if (this.tabIndex === 1) {
      //   this.getContactList();
      // }
      this.tabIndex = parseInt(event.target.getAttribute('data-tab-index'));
    },
    async getContactList() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } else {
        entity_id = this.associationData;
      }
      // if (this.batch.tranche_id.length > 0) {
      //   console.log(this.batch.tranche_id);

      this.load = true;
      await ApiService.get(
        'entity/contract/list?tranche_id=' +
          this.batch.tranche_id +
          '&entity_info_id=' +
          entity_id +
           '&dli_id=' +
           this.dli_id
      )
        .then((response) => {
          this.tableData = response.data.data;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
      // } else {
      //   Swal.fire({
      //     title: "Please Select Tranche!",
      //     icon: "warning",
      //     buttonsStyling: false,
      //     confirmButtonText: "Close",
      //     customClass: {
      //       confirmButton: "btn btn-danger",
      //     },
      //   });
      // }
    },

    async formSubmit() {
      this.loading = true;
      let user_id = VueCookieNext.getCookie('_seip_user');
      this.contract.user_id = user_id.id;
      let data = {
        contract: this.contract,
      };

      await ApiService.post('entity/contract/save', data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: 'Success',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.contract = {
                dli_id: '',
                id: '',
                entity_info_id: '',
                tranche_id: '',
                contract_number: '',
                contract_name: '',
                sign_date: '',
                start: '',
                end: '',
                target: '',
                amount: '',
                contribution_percentage: '',
                adjustable_advance_amount: '',
                min_age: '',
                max_age: '',
                age_grace_in_month: '',
                active_status: '',
                status_remarks: '',
                user_id: '',
              };
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
    // searchItems() {
    //   if (this.search !== "") {
    //     let results: never[] = [];
    //     for (let j = 0; j < this.tableData.length; j++) {
    //       if (this.searchingFunc(this.tableData[j], this.search)) {
    //         results.push(this.tableData[j]);
    //       }
    //     }
    //     this.tableData.splice(0, this.tableData.length, ...results);
    //   } else {
    //     Object.assign(this.tableData, this.lists);
    //   }
    // },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === 'object')) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    edit(data) {
      // console.log(
      //   DrawerComponent?.getInstance(
      //     "kt_drawer_association_contract"
      //   )?.goElement()
      // );
      //this.openDrawer();
      this.emitter.emit('contract-edit-modal-data', data);
    },

    add() {
      this.$router.push({
        name: 'entity employee',
        params: {
          id: 1,
        },
      });
    },

    view(id) {
      this.emitter.emit('edit-modal-data-view', id);
    },

    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('entity/contract/delete/' + `${id}`)
            .then((response) => {
              if ((response.data.status = 'error')) {
                Swal.fire({
                  title: 'Error!',
                  html: response.data.message,
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Close',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                });
              } else {
                Swal.fire({
                  title: 'Deleted!',
                  text: response.data.data,
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.emitter.emit('association-updated', true);
                });
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
